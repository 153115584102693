import React from "react";
import Button from "./button";

const HomeHero = ({ surtitle, title, intro, buttonText, buttonLink }) => {
  return (
    <section className="banner -primary -large">
      <div className="inner-wrapper">
        <div className="banner-inner">
          <div className="banner-text-content">
            {surtitle && (
              <h1 className="banner-subtitle subtitle-two">{surtitle}</h1>
            )}
            {surtitle && title && <h2 className="banner-title h1">{title}</h2>}

            {intro && <p className="banner-intro">{intro}</p>}

            {buttonLink && buttonText && (
              <Button
                type="primary"
                button={{
                  link: buttonLink,
                  text: buttonText,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
