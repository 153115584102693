import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import HomeHero from "../components/home-hero";
import SEO from "../components/seo";

const Error = () => {
  return (
    <Layout className="error">
      <SEO title="404" />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HomeHero
        surtitle="404"
        title="The page you are looking for does not exist"
        buttonText="Return home"
        buttonLink={{
          link: "/",
        }}
      />
    </Layout>
  );
};

export default Error;
