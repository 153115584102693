import React from "react";
import { Link } from "gatsby";
import linkHelper from "../helper/linkHelper";
import primary from "../img/diagonal-white.svg";
import secondary from "../img/diagonal-black.svg";

/**
- A button module featuring primary, secondary and ghost variations.
- Consists of a link url* and link text*
**/

const icon = {
  primary: primary,
  secondary: secondary,
};

const Button = ({ button, text, link, type }) => {
  if (!button) {
    button = {
      text,
      link,
    };
  }
  const linkObject = linkHelper(button.link);
  return (
    button.text &&
    linkObject?.link && (
      <React.Fragment>
        {linkObject.type === "internal" && (
          <Link
            className={`button subtitle-two -${type ? type : "primary"}`}
            to={linkObject.link}
          >
            {button.text}
            <img
              src={type === "primary" ? icon.primary : icon.secondary}
              alt=""
            />
          </Link>
        )}
        {linkObject.type === "external" && (
          <a
            className={`button subtitle-two -${type ? type : "primary"}`}
            href={linkObject.link}
            target="_blank"
            rel="noreferrer"
          >
            {button.text}
            <img
              src={type === "primary" ? icon.primary : icon.secondary}
              alt=""
            />
          </a>
        )}
      </React.Fragment>
    )
  );
};

export default Button;
